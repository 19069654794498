import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import dynamic from "next/dynamic";
import Head from "next/head";
import React, { useEffect, useState } from "react";
import { MouseEventHandler, ReactNode } from "react";
import { CONTRACT_ADDRESS, WEBSITE_URL } from "../constants/env";

const MintBox = dynamic(() => import("../components/MintBox"), { ssr: false });

export default function Home() {
  const [currentCard, setCurrentCard] = useState(0);

  useEffect(() => {
    const intervalId = window.setInterval(() => {
      setCurrentCard((card) => (card === 9 ? 0 : card + 1));
    }, 600);

    return () => window.clearInterval(intervalId);
  }, []);

  return (
    <>
      <Head>
        <title>God Loses NFTees</title>

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="keywords" content="NFT, Free, Ethereum, God" />
        <link rel="icon" href="/favicon.ico" />

        <meta property="og:title" content="God Loses NFTees" />
        <meta property="og:type" content="article" />
        <meta property="og:description" content="A squad of 10k losers, sharing my fucked up life as a loser I NEVER lucky" />
        <meta property="og:image" content="/background.webp" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content="God Loses NFTees" />
        <meta property="twitter:site" content={WEBSITE_URL} />
        <meta property="twitter:description" content="A squad of 10k losers, sharing my fucked up life as a loser I NEVER lucky" />
        <meta property="twitter:image" content="/background.webp" />
      </Head>

      <div className="w-full grid">
        <div className="w-full relative grid grid-cols-1 grid-rows-1 row-start-1 col-start-1 select-none -z-10">
          <img src="/background.webp" alt="" />
        </div>

        <nav className="absolute top-0 w-full flex flex-row justify-end items-center p-4 gap-3">
          {[
            [`https://etherscan.io/address/${CONTRACT_ADDRESS}`, "Etherscan", "/etherscan.webp"],
            ["https://opensea.io/collection/god-loses-nftees", "Opensea", "/opensea.webp"],
            ["https://twitter.com/GodLosesNFTees", "Twitter", "/twitter.webp"],
          ].map(([href, title, icon], idx) => (
            <a key={idx} className="w-24 h-24 select-none" href={href as string} target="_blank" rel="noreferrer">
              <img className="w-full aspect-square" src={icon} alt="" />
            </a>
          ))}

          <ConnectButton.Custom>
            {(modal) => {
              let children: ReactNode;
              let onClick: MouseEventHandler;

              if (!modal.mounted || !modal.account || !modal.chain) {
                onClick = modal.openConnectModal;
                children = <>{modal.connectModalOpen ? "Connecting..." : "Connect Wallet"}</>;
              } else if (modal.chain.unsupported) {
                children = <>{modal.chainModalOpen ? "Switching" : "Switch Network"}</>;
                onClick = modal.openChainModal;
              } else {
                children = (
                  <>
                    {modal.account.displayName} - {modal.account.displayBalance}
                  </>
                );
                onClick = modal.openAccountModal;
              }

              return (
                <button
                  className={`py-2 px-4 flex flex-row justify-center items-center gap-2 h-8 rounded-lg transition-all duration-300 border-2 border-black disabled:bg-gray-500 disabled:border-gray-400
                  ${
                    modal.chain?.unsupported === true
                      ? " bg-rose-700 hover:bg-rose-600 active:bg-rose-900 active"
                      : " bg-cyan-700 hover:bg-cyan-600 active:bg-cyan-900 active"
                  }`}
                >
                  <FontAwesomeIcon className="aspect-square h-full" icon={faWallet} color="white" />
                  <div className="text-white font-bold text-left" onClick={onClick}>
                    {children}
                  </div>
                </button>
              );
            }}
          </ConnectButton.Custom>
        </nav>

        <main className="row-start-1 col-start-1 ">
          <MintBox />
        </main>
      </div>
    </>
  );
}
